import { API_BASE } from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { mapAccountsTable, mapZonesToNeighborhoodOptions, mapCustomerMapPoints } from '@/utils/mappers/AccountsTableMap'
import type { AccountApiResponse } from '@/components/account/types'
import type { TableRow, TableFilter, BatchActionForm } from '@/components/table/types'
import { ref, computed } from 'vue'
import { useZones } from '@/composables/useZones'
import { useAuth } from '@/composables/useAuth'
import type { AccountStatus } from '@/types/account-statuses'
import type { AccountsMapMarker } from '@/composables/useAccountsMapbox'
import { useToast } from '@/composables/useToast'

type AccountRequestBody = {
  page: number
  limit: number | null
  sortColumn: string
  sortOrder: 'ASC' | 'DESC'
  searchValues: {
    [key: string]: string | number | string[] | number[]
  }
}

type CableStatuses = {
	pulled: boolean
	spliced: boolean
	terminated: boolean
}

type MapAccountsListResponseCustomer = {
	accountId: number
	accountStatus: AccountStatus
	latitude: string
	longitude: string
	zoneId: number
	siteSurveyStatus: string
	insideInstallStatus: string
	outsideInstallStatus: string
	cableStatuses: CableStatuses
	templineStatus: string | null
	signupDate: string
	hasEquipment: boolean
}

type MappedCustomer = {
	id: string
	accountStatus: AccountStatus
	position: google.maps.LatLngLiteral
	color: string
	zIndex: number
	zoneId: string
	siteSurveyStatus: string
	insideInstallStatus: string
	outsideInstallStatus: string
	cablePulled: boolean
	cableSpliced: boolean
	cableTerminated: boolean
	templineStatus: string | null
	hasEquipment: boolean | string
}

type MapboxMapCustomer = {
	id: string
	type: 'Point'
	zoneId: string
	accountStatus: AccountStatus
	coordinates: number[],
	surveyStatus: string
	insideInstallStatus: string,
	hasEquipment: string
}

const tableData = ref<TableRow[]>([])
const totalItems = ref(0)
const loading = ref(true)
const timeLastFetched = ref(0)
const requestBody = ref<AccountRequestBody>({
	page: 1,
	limit: null,
	sortColumn: 'id',
	sortOrder: 'ASC',
	searchValues: {}
})
// used to store the last fetched request body before lassoing
// to be able to refetch the same data if lassoing is canceled.
const storedRequestBody = ref<AccountRequestBody>({
	page: 1,
	limit: null,
	sortColumn: 'id',
	sortOrder: 'ASC',
	searchValues: {}
})

const customers = ref<MappedCustomer[]>([])
const mapboxMarkers = ref<AccountsMapMarker[]>([])

const showTableMarkers = ref(false)

const batchActionLoading = ref(false)
const batchActionSuccess = ref(false)
const batchActionError = ref(false)
const skippedAccounts = ref([])

const limit = computed(() => requestBody.value.limit === null ? totalItems.value : null)

export const useAccounts = () => {
	const { getZones, zones } = useZones()
	const { bugsnagReport } = useErrorReporter()
	const { userData } = useAuth()

	if (!zones.value?.length) {
		getZones()
	}

	const neighborhoodOptions = computed<TableFilter[]>(() => mapZonesToNeighborhoodOptions(zones.value) || [])

	const refetchAccounts = async (data?: { body?: AccountRequestBody, store?: boolean, restore?: boolean }) => {
		const { body, store, restore } = data || {}

		await fetchAccounts({ body, refetch: true, store, restore })
	}

	const fetchAccounts = async (data?: { body?: AccountRequestBody, store?: boolean, restore?: boolean, refetch?: boolean }) => {
		const { body, store, restore, refetch } = data || {}

		if (!userData.value) { return } // Don't fetch if user data is not available

		// If we're restoring, override requestBody with the stored version.
		if (restore) {
			requestBody.value = storedRequestBody.value
		} else if (body) {
			// If we need to store the current requestBody before updating (e.g. for lasso actions)
			if (store && !storedRequestBody.value) { // Don't overwrite the stored request if it exists to prevent further lassoing from overwriting it.
				storedRequestBody.value = requestBody.value
			}
			requestBody.value = body
		}

		// If not a refetch and data exists recently, skip fetching.
		const nextFetchTime = timeLastFetched.value + 10 * 60000
		if (!refetch && tableData.value?.length && Date.now() < nextFetchTime) {
			return
		}

		timeLastFetched.value = Date.now()
		loading.value = true
		await $fetch(`${API_BASE}/portal/accounts`, {
			method: 'POST',
			credentials: 'include',
			server: false,
			body: requestBody.value,
			onResponse ({ response }) {
				if (!response?.ok) {
					bugsnagReport({
						error: response._data?.error || response._data?.errors?.[0] || 'Error fetching Accounts data',
						showToast: true,
						toast: {
							title: 'Error fetching accounts',
							message: response._data?.error || response._data?.errors?.[0]
						}
					})
				}

				const responseData: AccountApiResponse[] = response?._data?.accounts
				tableData.value = mapAccountsTable(responseData)
				totalItems.value = response?._data?.total
			}
		}).catch((e) => {
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error fetching accounts data',
					message: e
				}
			})
		}).finally(() => {
			loading.value = false
		})
	}

	const getAllAccountsForMap = async () => {
		await $fetch(`${API_BASE}/portal/map/accounts/list`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0] || 'Error fetching Accounts data for map')
				}
				const responseData: MapAccountsListResponseCustomer[] = response?._data
				customers.value = mapCustomerMapPoints(responseData)
				mapboxMarkers.value = customers.value?.map((customer: MappedCustomer): MapboxMapCustomer => ({
					id: customer?.id || '',
					type: 'Point',
					coordinates: [ customer?.position?.lng || 0, customer?.position?.lat || 0 ],
					accountStatus: customer?.accountStatus || '',
					zoneId: customer?.zoneId || '',
					surveyStatus: customer?.siteSurveyStatus || '',
					insideInstallStatus: customer?.insideInstallStatus || '',
					hasEquipment: customer?.hasEquipment ? 'true' : 'false'
				})) || {}
			}
		}).catch((e) => {
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error fetching accounts data for map',
					message: e
				}
			})
		})
	}

	const performBatchAction = async (formData: BatchActionForm) => {
		batchActionLoading.value = true
		const { addToast } = useToast()

		const actionEndpoint = formData.actionOption?.endpoint
		const { url, params } = actionEndpoint || {}
		const itemIds = formData.selectedItems?.map((item: TableRow) => item.id) || formData.selectedIds || []

		const requestBody = {
			accountIds: itemIds,
			scheduleType: params?.scheduleType || ''
		}

		await $fetch(`${API_BASE}${url}`, {
			method: 'POST',
			credentials: 'include',
			body: requestBody,
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.errors?.[0]?.title || `Error performing ${formData.actionOption?.label}.`)
				}

				if (!response._data?.success) {
					throw new Error(`Email for ${formData.actionOption?.label} was already sent recently.`)
				}

				batchActionSuccess.value = true
				if (response._data.skippedAccounts.length) {
					skippedAccounts.value = response._data.skippedAccounts
				} else {
					skippedAccounts.value = []
				}
				if (formData.showSuccessToast) {
					addToast({
						notificationType: 'success',
						title: 'Success!',
						message: `${formData.actionOption?.label} completed successfully.`
					})
				}
			}
		}).catch((e) => {
			batchActionError.value = true
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error performing batch action',
					message: e
				}
			})
		}).finally(() => {
			batchActionLoading.value = false
		})
	}

	const clearBatchActionState = () => {
		batchActionLoading.value = false
		batchActionSuccess.value = false
		batchActionError.value = false
		skippedAccounts.value = []
	}

	const movingInThisMonth = (moveInDate: string): boolean => {
		const moveIn = new Date(moveInDate)
		const today = new Date()
		const nextMonth = new Date()
		nextMonth.setDate(today.getDate() + 30)
		const withinNextMonth = moveIn >= today && moveIn <= nextMonth
		return withinNextMonth
	}

	const isPastDue = (moveInDate: string): boolean => {
		const moveIn = new Date(moveInDate)
		const today = new Date()
		return moveIn < today
	}

	return {
		refetchAccounts,
		fetchAccounts,
		getAllAccountsForMap,
		movingInThisMonth,
		isPastDue,
		performBatchAction,
		clearBatchActionState,
		batchActionLoading,
		batchActionSuccess,
		batchActionError,
		skippedAccounts,
		requestBody,
		storedRequestBody,
		tableData,
		totalItems,
		limit,
		neighborhoodOptions,
		loading,
		customers,
		mapboxMarkers,
		showTableMarkers
	}
}

export type { AccountRequestBody, MapAccountsListResponseCustomer, MappedCustomer }
